import { getColor } from '@/utils'
import { cssRaw, style } from 'typestyle'

const zoomButtons = style({
  position: 'absolute',
  top: 14,
  left: 15,
})

const zoomButton = style({
  textAlign: 'center',
  position: 'relative',
  border: 'none',
  paddingBottom: '5px',
  cursor: 'pointer',
  backgroundColor: getColor('primary-blue'),
  width: '30px',
  height: '20px',
  color: 'white',
  zIndex: 3000,
  fontSize: '16px',

  $nest: {
    '&:first-child': {
      marginRight: '1px',
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
    },
    '&:last-child': {
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
    },
  },
})

cssRaw(`
.e-diagram-resize-handle {
  display: none !important;
}
`)

export const modalText = style({
  display: 'inherit !important',
})

export default {
  zoomButtons,
  zoomButton,
}
