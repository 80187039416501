
import {
  defineComponent,
  onMounted,
  PropType,
  Ref,
  ref,
  watchEffect,
} from 'vue'
import * as yup from 'yup'
import { useForm } from 'vee-validate'
import { useRouter } from 'vue-router'
import { formError, loading } from '@/components/ui/styles'
import { Extension } from '@/types/models/productFlowBuilder'
import ProjectsService from '@/graphqlBackOffice/projects/service'
import { find } from 'lodash'
import ProductBuilderService from '@/graphqlBackOffice/productBuilder/service'

export default defineComponent({
  name: 'ExtensionFormModal',

  props: {
    extension: {
      type: Object as PropType<Extension>,
      default: null,
    },
    productId: {
      type: String,
      required: true,
    },
  },

  emits: ['added', 'close', 'change'],

  setup(props, { emit }) {
    const { currentRoute } = useRouter()
    const { project: projectById, loading: loadingProject } =
      ProjectsService.getProjectById(
        currentRoute.value?.params.id as string,
        'cache-first'
      )
    const options: Ref = ref([])
    const saving = ref(false)
    const error = ref('')

    const { handleSubmit, meta, resetForm, setValues, values } = useForm({
      validationSchema: yup.object().shape({
        extension: yup.string().required('This field is required.'),
      }),
    })

    onMounted(() => {
      if (props.extension) {
        setValues({ extension: props.extension.name })
      }
    })

    watchEffect(async () => {
      if (!loadingProject.value) {
        if (projectById.value?.name) {
          const res = await ProjectsService.getConfig()
          options.value = res.data[projectById.value?.name]?.map(
            (item: any) => ({
              label: item.name,
              value: item.name,
              entryPoint: item.name,
            })
          )
        }
      }
    })
    const { createExtension, updateExtension } =
      ProductBuilderService.useMutations()

    const onSubmit = handleSubmit(async () => {
      try {
        saving.value = true
        const extension = find(
          options.value,
          (item) => item.label === values.extension
        )
        if (!props.extension) {
          const newExtension = await createExtension.mutate({
            record: {
              name: extension.label,
              entryPoint: extension.entryPoint,
              productId: props.productId,
            },
          })

          if (newExtension?.data?.createExtension?.record) {
            emit('added', newExtension.data.createExtension.record)
          }
        } else {
          const editedExtension = await updateExtension.mutate({
            filter: { _id: props.extension?._id },
            record: {
              name: extension.label,
              entryPoint: extension.entryPoint,
            },
          })
          if (editedExtension?.data?.updateExtension?.record) {
            emit('change', editedExtension.data.updateExtension.record)
          }
        }
        closeModal()
      } catch (error) {
        error.value = 'Something went wrong, please try again later.'
      } finally {
        saving.value = false
      }
    })

    const closeModal = () => {
      resetForm()
      error.value = ''
      saving.value = false
      emit('close')
    }

    return {
      closeModal,
      css: { formError, loading },
      error,
      meta,
      onSubmit,
      options,
      saving,
    }
  },
})
