import { style } from 'typestyle'
import { getColor } from '@/utils'

const pageContainer = (nodeWidth: number, nodeHeight?: string): string =>
  style({
    position: 'relative',
    backgroundColor: getColor('secondary-gray'),
    height: nodeHeight || '305px',
    minWidth: `${nodeWidth}px`,
    borderRadius: '5px',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
    $nest: {
      '&:hover': {
        $nest: {
          '.e-native-layer': {
            zIndex: 1,
          },
        },
      },
    },
  })

const container = style({
  width: '130px',
  marginLeft: '30px',
  paddingTop: '30px',
})

export const seamlessPreviewMobile = (previewImage: string): string =>
  style({
    height: '264px',
    width: '128px',
    textAlign: 'center',
    background: `url(${previewImage}) center`,
    backgroundSize: 'cover',
    margin: 'auto',
  })

const dragHandleIcon = style({
  position: 'absolute',
  cursor: 'pointer',
  top: 10,
  left: 10,
  width: '16px',
  height: '10px',
})

const pageTitle = style({
  position: 'absolute',
  top: 8,
  left: 36,
  fontSize: '12px',
  color: getColor('white'),
  userSelect: 'none',
})

const extensionName = style({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '12px',
  color: getColor('white'),
  userSelect: 'none',
})

const pageEditIcon = style({
  position: 'absolute',
  cursor: 'pointer',
  top: 30,
  left: 10,
  width: '14px',
  height: '14px',
  zIndex: 11,
})

const pageRemoveIcon = style({
  position: 'absolute',
  cursor: 'pointer',
  top: 52,
  left: 10,
  width: '11px',
  height: '13px',
})

const title = style({
  position: 'absolute',
  top: '125px',
  background: getColor('white'),
  left: '47px',
  fontSize: '12px',
  lineHeight: '14px',
  width: '198px',
  textAlign: 'center',
  zIndex: 2,
  height: '35px',
})

const description = style({
  position: 'absolute',
  top: '165px',
  left: '45px',
  fontSize: '9px',
  lineHeight: '10px',
  width: '208px',
  textAlign: 'center',
  zIndex: 2,
  height: '35px',
})

const offers = (): string =>
  style({
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '80px',
    paddingLeft: '17px',
  })

const linearGradient = style({
  position: 'absolute',
  height: '286px',
  width: '41px',
  background:
    'linear-gradient(270deg, rgba(128, 128, 128, 0.43) 0%, rgba(196, 196, 196, 0) 69.27%)',
  top: '200px',
  left: '707px',
})

const footer = style({
  fontSize: '9px',
  position: 'absolute',
  bottom: '83px',
  color: getColor('secondary-gray'),
  left: '51px',
  lineHeight: '10px',
  width: '198px',
  textAlign: 'center',
  height: '35px',
})

const previewLoader = (image: string): string =>
  style({
    height: '593px',
    background: `url(${image}) center no-repeat`,
    width: '273px',
    border: `15px ${getColor('white')} solid`,
    borderRadius: '45px',
    textAlign: 'center',
    boxShadow: '0 -1px 4px rgba(0, 0, 0, 0.25)',
    backgroundSize: 'cover',
    margin: 'auto',
  })

export const previewIframe = style({
  zoom: '0.9',
  transform: 'scale(0.82)',
  transformOrigin: '0 0',
  width: '351px',
  height: '672px',
  border: 'none',
  marginLeft: '17px',
  marginTop: '85px',
  borderBottomRightRadius: '35px',
  borderBottomLeftRadius: '35px',
})

export const iframeWrapper = style({
  position: 'relative',
})

export const addressBar = style({
  background: getColor('secondary-light-gray'),
  fontSize: '10px',
  color: `${getColor()}`,
  borderRadius: '15px',
  height: '20px',
  width: '249px',
  padding: '3px',
  marginLeft: '19px',
  position: 'absolute',
  top: '52px',
})

export const topBar = style({
  fontSize: '12px',
  color: `${getColor()}`,
})

export const previewLocalUpload = style({
  top: '80px',
  left: '25px',
  textAlign: 'start',
  zIndex: 6,
  position: 'absolute',
  background: 'white',
  width: '100px',
  lineHeight: '27px',
  height: '24px',
  $nest: {
    img: {
      maxWidth: '22px',
      maxHeight: '22px',
    },
  },
})

export default {
  extensionName,
  pageContainer,
  container,
  seamlessPreviewMobile,
  dragHandleIcon,
  pageTitle,
  pageEditIcon,
  pageRemoveIcon,
  previewIframe,
  addressBar,
  iframeWrapper,
  previewLocalUpload,
  topBar,
  previewLoader,
  offers,
  footer,
  title,
  description,
  linearGradient,
}
