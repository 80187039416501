import { style } from 'typestyle'
import { getColor } from '@/utils'

const card = (): string =>
  style({
    display: 'flex',
    flexDirection: 'column',
    margin: '0 2px',
    borderRadius: '19px',
  })

const cardContent = style({
  padding: '5px 7px',
  height: '71px',
  width: '91px',
  backgroundColor: getColor('secondary-light-gray'),
  display: 'flex',
  flexDirection: 'column',
})

const title = style({
  fontSize: '4px',
  lineHeight: '5px',
  marginBottom: '3px',
})

const image = (image: string): string =>
  style({
    height: '46px',
    width: '91px',
    background: `url(${image}) center`,
    backgroundSize: 'cover',
    // borderRadius: '7px 7px 0 0',
  })

const descriptionList = style({
  fontSize: '4px',
  lineHeight: '5px',
  color: getColor('secondary-gray'),
  listStyle: 'none',
  $nest: {
    li: {
      position: 'relative',
      paddingLeft: '2px',
      textAlign: 'left',
    },
    'li::before': {
      position: 'absolute',
      left: '0',
      content: '"•"',
    },
  },
})

const buttons = style({
  $nest: {
    button: {
      height: '10px',
      cursor: 'pointer',
      width: '73px',
      borderRadius: '2px',
      fontSize: '4px',
      lineHeight: '5px',
      fontWeight: 500,
      background: getColor('white'),
      marginTop: '0px',
      marginBottom: '2px',
      border: 'none',
    },
  },
})

export default {
  card,
  title,
  buttons,
  descriptionList,
  cardContent,
  image,
}
