
import { defineComponent, PropType } from 'vue'
import { OfferListItem, ReadMoreRow } from '@/types/models'
import { pageSeamlessPreviewTemplateStyle as css } from '../styles'
import BckIcon from '@/components/ui/BckIcon.vue'
import SeamlessPreview from '@/components/common/SeamlessPreview.vue'

export default defineComponent({
  components: { BckIcon, SeamlessPreview },
  props: {
    type: {
      type: String,
      required: true,
    },
    nodeId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    offers: {
      type: Array as PropType<OfferListItem[]>,
      default: () => [],
    },
    readMoreRows: {
      type: Array as PropType<ReadMoreRow[]>,
      default: () => {
        return []
      },
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    nodeWidth: {
      type: Number,
      required: true,
    },
    onHover: {
      type: Function,
      default: null,
      required: false,
    },
    showHelpers: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    carouselView: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Object,
      default: undefined,
    },
    nav: {
      type: Object,
      default: undefined,
    },
  },

  setup() {
    return {
      css,
    }
  },
})
