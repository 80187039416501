import { getColor } from '@/utils'
import { style } from 'typestyle'

const container = style({
  width: '165px',
  height: '78vh',
  backgroundColor: getColor('white'),
  borderRadius: '5px',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
  padding: '10px',
})

const pagesStructureTitle = (selected: boolean): string =>
  style({
    fontSize: '14px',
    paddingBottom: '8px',
    color: selected ? getColor('primary-blue') : getColor('primary-navy'),
  })

const pagesStuctureSepparator = style({
  fontSize: '8px',
  fontWeight: 600,
  color: getColor('secondary-gray'),
  borderTop: `1px solid ${getColor('secondary-light-gray')}`,
  marginTop: '10px',
  display: 'flex',
  justifyContent: 'flex-end',
  $nest: {
    span: {
      backgroundColor: getColor('secondary-light-gray'),
      padding: '1px 2px 2px 2px',
      borderBottomLeftRadius: '2px',
      borderBottomRightRadius: '2px',
    },
  },
})

export default {
  container,
  pagesStructureTitle,
  pagesStuctureSepparator,
}
