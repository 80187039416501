import { PointModel } from '@syncfusion/ej2-diagrams'

export const getNodeWidth = (offersLength: number): number =>
  offersLength === 0 || offersLength === 1 ? 200 : (offersLength - 1) * 95 + 160

export const getPortOffset = (
  nodeWidth: number,
  cardIndex: number,
  buttonIndex: number
): PointModel => {
  // buttonIndex - delay ports if multiple buttons
  const startingPosition = 68.25 + buttonIndex * 10
  const cardWidth = 91
  const cardPadding = 8

  const offsetX =
    (startingPosition + cardIndex * (cardWidth + cardPadding)) / nodeWidth

  const buttonStartOffset = 0.674
  const offsetY = buttonStartOffset + buttonIndex * 0.037

  return {
    x: offsetX,
    y: offsetY,
  }
}
