
import { defineComponent } from 'vue'
// import { getStructure } from '../helpers'
import { pagesStructureStyle as css } from '../styles'
import { longText } from '@/components/ui/styles'

export default defineComponent({
  components: {},
  props: {
    connectedPages: {
      type: Array,
      default: () => [],
    },
    disconnectedPages: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['select-page'],

  setup() {
    return {
      css: { ...css, longText },
    }
  },
})
