import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckH6 = _resolveComponent("BckH6")!
  const _component_LanguageTabs = _resolveComponent("LanguageTabs")!
  const _component_BckInput = _resolveComponent("BckInput")!
  const _component_BckIcon = _resolveComponent("BckIcon")!
  const _component_BckGridColumn = _resolveComponent("BckGridColumn")!
  const _component_BckGrid = _resolveComponent("BckGrid")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css.icons)
  }, [
    _createVNode(_component_BckH6, { margin: "small no" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.title), 1)
      ]),
      _: 1
    }),
    (_ctx.languages?.length > 1)
      ? (_openBlock(), _createBlock(_component_LanguageTabs, {
          key: 0,
          class: _normalizeClass(`${_ctx.css.languageTabs} language-tabs`),
          "selected-language": _ctx.selectedLanguage,
          languages: _ctx.languages,
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeLanguage($event)))
        }, null, 8, ["class", "selected-language", "languages"]))
      : _createCommentVNode("", true),
    _createVNode(_component_BckGrid, null, {
      default: _withCtx(() => [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (index) => {
          return _createElementVNode("div", { key: index }, [
            _createVNode(_component_BckInput, {
              class: _normalizeClass(_ctx.css.label),
              name: `label[${index - 1}]`,
              placeholder: "Name",
              disabled: _ctx.disabled,
              onChange: ($event: any) => (_ctx.changeLabel($event, index - 1))
            }, null, 8, ["class", "name", "disabled", "onChange"]),
            _createVNode(_component_BckGridColumn, {
              size: "4/12",
              align: "center",
              padding: "small lMedium small no",
              onClick: ($event: any) => (_ctx.handleSelect(index - 1))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.css.iconSelect)
                }, [
                  _createVNode(_component_BckGrid, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_BckGridColumn, {
                        size: "8/12",
                        align: "center",
                        padding: "no"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_BckIcon, {
                            fill: "none",
                            "icon-name": _ctx.iconName[index - 1],
                            "view-box": "-5 -10 36 36"
                          }, null, 8, ["icon-name"])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_BckGridColumn, {
                        size: "4/12",
                        align: "center",
                        padding: "small no no no"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_BckIcon, {
                            class: _normalizeClass(_ctx.displayIcons[index - 1] ? _ctx.css.arrowSelected : ''),
                            "icon-name": "arrowNavIcon",
                            "view-box": "0 0 30 30"
                          }, null, 8, ["class"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ], 2),
                (_ctx.displayIcons[index - 1])
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(_ctx.css.groupIcons)
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableIcons.length, (gindex) => {
                        return (_openBlock(), _createBlock(_component_BckIcon, {
                          key: gindex,
                          width: "36px",
                          fill: "none",
                          class: _normalizeClass(_ctx.css.groupItem),
                          "icon-name": _ctx.availableIcons[gindex - 1],
                          "view-box": "-4 -4 33 33",
                          onClick: ($event: any) => (_ctx.select(_ctx.availableIcons[gindex - 1], index - 1))
                        }, null, 8, ["class", "icon-name", "onClick"]))
                      }), 128))
                    ], 2))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        }), 64))
      ]),
      _: 1
    })
  ], 2))
}