import { style } from 'typestyle'
import { getColor, Color } from '@/utils'

const navBarPages = style({
  borderRadius: '5px',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
})

const navigationContainer = style({
  borderTop: `1px solid ${getColor('secondary-gray')}`,
  minHeight: '500px',
  fontSize: '12px',
})

const borderRight = style({
  borderRight: `1px solid ${getColor('secondary-gray')}`,
})

const pageElement = (color: Color): string =>
  style({
    display: 'flex',
    alignItems: 'center',
    color: getColor(color),
    fontWeight: 'bold',
    fontSize: '12px',
  })

const container = style({
  overflow: 'visible !important',
})

export default {
  borderRight,
  navBarPages,
  navigationContainer,
  pageElement,
  container,
}
