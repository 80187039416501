
import { pageTypeCardStyle } from './styles'
import { defineComponent } from 'vue'
import { getColor } from '@/utils'

export default defineComponent({
  props: {
    name: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['selected'],

  setup() {
    return { pageTypeCardStyle, getColor }
  },
})
