/* eslint-disable @typescript-eslint/no-explicit-any */
import { getColor } from '@/utils'
import {
  AnnotationConstraints,
  Connector,
  ConnectorConstraints,
  ConnectorModel,
  Diagram,
  NodeConstraints,
  NodeModel,
  PortConstraints,
  PortModel,
  PortVisibility,
} from '@syncfusion/ej2-diagrams'
import { find, flatten, forEach, get, map } from 'lodash'
import { getPortOffset } from '.'

interface NodeValues {
  x: number
  y: number
  w: number
  h: number
}

const connectorConfiguration = {
  constraints: ConnectorConstraints.Default & ~ConnectorConstraints.Drag,
  // ~ConnectorConstraints.DragSourceEnd &
  // ~ConnectorConstraints.DragTargetEnd,
}

const nodesConfiguration = {
  constraints:
    NodeConstraints.Default & ~NodeConstraints.Rotate & ~NodeConstraints.Resize,
  annotations: [
    {
      constraints: AnnotationConstraints.ReadOnly,
    },
  ],
}

const defaultConnectorConfiguration = {
  type: 'Bezier',
  style: {
    strokeColor: getColor('primary-blue'),
    fill: getColor('primary-blue'),
    strokeWidth: 2,
  },
  targetDecorator: {
    shape: 'None',
  },
  cornerRadius: 10,
  constraints: ConnectorConstraints.None,
}

const savedConnectorConfiguration = {
  type: 'Bezier',
  style: {
    strokeColor: getColor('primary-red'),
    fill: getColor('primary-red'),
    strokeWidth: 2,
  },
  targetDecorator: {
    shape: 'OpenArrow',
    style: {
      fill: getColor('primary-red'),
      strokeColor: getColor('primary-red'),
    },
    width: 14,
    height: 14,
  },
  cornerRadius: 10,
  constraints: connectorConfiguration.constraints,
}

const getPageNameById = (pageId: string, pages: any) => {
  let pageName = ''
  map(pages, (page: any) => {
    if (page.navigation === pageId) {
      pageName = page.name
    }
  })

  return pageName
}

const getOtherConnectedPages = (page: any, pages: any) => {
  const otherConnectedPages: any = []
  page.offers.map((offer: any) => {
    offer.buttons.map((button: any) => {
      if (
        (button.payloadType === 'INTERNAL' ||
          button.payloadType === 'EXTENSION') &&
        !checkIfReadMorePage(button.payload, pages)
      ) {
        const page = find(pages, (page) => button.payload === page.navigation)
        otherConnectedPages.push({
          _id: page._id,
          id: button.pageIdPayload,
          name: page.name,
        })
      }
    })
  })

  return otherConnectedPages
}

export const getStructure = (configuration: any): any => {
  const connectedPages: any = []
  const disconnectedPages: any = []

  // Get menu pages
  configuration.pages.forEach((page: any) => {
    if (configuration.configuration.bottomNavigator.indexOf(page.id) > -1) {
      connectedPages.push({
        id: page.id,
        _id: page._id,
        name: page.name,
        selected: false,
      })
    }
  })

  // Get other connected pages
  configuration.pages.forEach((page: any) => {
    const otherConnectedPages = page.offers
      ? getOtherConnectedPages(page, configuration.pages)
      : []
    connectedPages.push(...otherConnectedPages)
  })

  const uniqueConnectedPages = findUnique(connectedPages, (d: any) => d.id)

  // Get disconnected pages
  configuration.pages.forEach((page: any) => {
    let isPageConnected = false
    uniqueConnectedPages.forEach((connectedPage: any) => {
      if (connectedPage.id === page.id) {
        isPageConnected = true
      }
    })

    if (!isPageConnected && page.type !== 'readMore') {
      disconnectedPages.push({ id: page.id, name: page.name, _id: page._id })
    }
  })

  return { connectedPages: uniqueConnectedPages, disconnectedPages }
}

const findUnique = (arr: any, predicate: any) => {
  const found: any = {}
  arr.forEach((d: any) => {
    found[predicate(d)]
      ? (found[predicate(d)].connections = found[predicate(d)].connections + 1)
      : (found[predicate(d)] = { ...d, connections: 1 })
  })

  return Object.keys(found).map((key) => found[key])
}

export const onConnectorDrag = (
  args: { element: Connector; cancel: boolean },
  diagram: Diagram
): boolean => {
  if (args.element.targetID === args.element.sourceID) {
    args.cancel = true

    return false
  }
  if (
    args.element &&
    args.element.targetPortID == '' &&
    args.element.targetID !== ''
  ) {
    args.element.targetPortID = `inport-${args.element.targetID}`
  }

  if (
    args.element &&
    (args.element.sourcePortID === '' || args.element.targetPortID == '')
  ) {
    args.cancel = true
    return false
  } else {
    if (args.element.sourcePortID && args.element.sourceID) {
      const node = diagram.getNodeObject(args.element.sourceID)

      const portOutEdges = find((node as NodeModel).ports, {
        id: args.element.sourcePortID,
      })?.outEdges

      if (portOutEdges && portOutEdges.length > 1) {
        args.cancel = true
        return false
      }
    }
    return true
  }
}

let startingPositionOffsetX = 0
let startingPositionOffsetY = 0
export const onNodeMove = (args: any, diagram: Diagram): void => {
  if (args.state === 'Start') {
    startingPositionOffsetX = args.source.properties.offsetX
    startingPositionOffsetY = args.source.properties.offsetY
  }

  if (args.state === 'Completed') {
    let node = null
    if (args.source.properties.id) {
      node = args.source.properties
    } else {
      node = args.source.properties.nodes[0]
    }
    const isCollision = checkCollisions(
      {
        x: node.offsetX,
        y: node.offsetY,
        w: node.minWidth,
        h: node.height,
      },
      node.id,
      diagram.nodes
    )

    if (isCollision) {
      node.offsetX = startingPositionOffsetX
      node.offsetY = startingPositionOffsetY
      // args.cancel = true
    }
  }
}

const checkCollisions = (
  nodeValues: NodeValues,
  nodeId: string,
  nodes: any
): boolean => {
  let isCollision = false
  nodes.forEach((node: any) => {
    const tempNodeValues = {
      x: node.offsetX,
      y: node.offsetY,
      w: node.minWidth,
      h: node.height,
    }

    if (
      node.id !== nodeId &&
      nodeValues.x < tempNodeValues.x + tempNodeValues.w &&
      nodeValues.x + nodeValues.w > tempNodeValues.x &&
      nodeValues.y < tempNodeValues.y + tempNodeValues.h &&
      nodeValues.h + nodeValues.y > tempNodeValues.y
    ) {
      isCollision = true
    }
  })

  return isCollision
}

export const onConnectorChange = (args: { cancel: boolean }): void => {
  args.cancel = true
}

export const getConnectorDefaults = (
  obj: ConnectorModel,
  defaultConnectors: string[]
): void => {
  if (
    obj.id &&
    obj.id.indexOf('connector') !== -1 &&
    !defaultConnectors.includes(obj.id)
  ) {
    obj.type = 'Bezier'
    obj.style = {
      strokeColor: getColor('primary-red'),
      fill: getColor('primary-red'),
      strokeWidth: 2,
    }
    obj.targetDecorator = {
      shape: 'OpenArrow',
      style: {
        fill: getColor('primary-red'),
        strokeColor: getColor('primary-red'),
      },
      width: 14,
      height: 14,
    }
    obj.cornerRadius = 10
    obj.constraints = connectorConfiguration.constraints
  }
}

export const getNodesConfiguration = (
  page: any,
  nodeWidth: number,
  pageTemplate: string,
  ports: any[]
): NodeModel => ({
  id: page.id,
  data: {
    type: page.type === 'extension' ? 'EXTENSION' : 'PAGE',
    name: page.name,
    _id: page._id,
  },
  minWidth: nodeWidth,
  height: page.type === 'extension' ? 80 : 305,
  offsetX: page.offsetX,
  offsetY: page.offsetY,
  constraints: nodesConfiguration.constraints,
  annotations: nodesConfiguration.annotations,
  shape: {
    type: 'HTML',
    content: pageTemplate,
  },
  ports: [
    ...ports,
    {
      id: `inport-${page.id}`,
      offset: {
        x: 0,
        y: 0.5,
      },
      style: {
        fill: 'red',
        strokeWidth: 2,
        strokeColor: 'red',
      },
      shape: 'Circle',
      constraints:
        page.type === 'extension'
          ? PortConstraints.InConnect
          : (PortConstraints.Default | PortConstraints.Draw) &
            ~PortConstraints.OutConnect &
            PortConstraints.InConnect,
      visibility: PortVisibility.Visible,
    },
  ],
})

const checkIfReadMorePage = (augmentId: string, pages: any) => {
  let isReadMore = false
  forEach(pages, (page) => {
    if (augmentId === page.navigation && page.readMoreRows) {
      isReadMore = true
    }
  })
  return isReadMore
}

export const getPortsAndConnectorsConfiguration = (
  pageId: string,
  index: number,
  nodeWidth: number,
  buttons: any[],
  pages: any
): any =>
  flatten(
    buttons.map((button: any, buttonIndex) => {
      if (!checkIfReadMorePage(button.payload, pages)) {
        let connector = null
        const buttonPort = {
          id: `outport-${pageId}-${index}-${button.id}`,
          offset: getPortOffset(nodeWidth, index, buttonIndex),
          width: 8,
          height: 8,
          style: {
            fill: 'transparent',
            strokeWidth: 1,
            strokeColor: 'red',
          },
          zIndex: -1,
          shape: 'Circle',
          constraints:
            (PortConstraints.Default | PortConstraints.Draw) &
            ~PortConstraints.InConnect,
          visibility: PortVisibility.Visible,
        }
        if (
          button.pageIdPayload &&
          (button.payloadType === 'INTERNAL' ||
            button.payloadType === 'EXTENSION') &&
          button.pageIdPayload !== pageId
        ) {
          connector = {
            id: `connector-${pageId}-${index}-${button.id}-${button.pageIdPayload}`,
            sourceID: pageId,
            sourcePortID: `outport-${pageId}-${index}-${button.id}`,
            targetID: button.pageIdPayload,
            targetPortID: `inport-${button.pageIdPayload}`,
            ...(savedConnectorConfiguration as ConnectorModel),
          }
        }

        return { buttonPort, connector }
      } else {
        return []
      }
    })
  )

export const getDefaultConnectorConfiguration = (
  connectorIndex: number,
  sourceId: string,
  targetId: string
): ConnectorModel => ({
  id: `connector${connectorIndex}`,
  sourceID: sourceId,
  targetID: targetId,
  ...(defaultConnectorConfiguration as ConnectorModel),
})

export const appendButtonEvents = (
  pages: any,
  showDeleteModal: any,
  showEditModal: any,
  setSelectedPage: any,
  editPage: any
): void => {
  pages.map((page: any) => {
    const editButton: HTMLElement | null = document.getElementById(
      `edit-${page.id}`
    )
    const removeButton: HTMLElement | null = document.getElementById(
      `remove-${page.id}`
    )

    editButton &&
      (editButton.onclick = function () {
        if (page.type === 'extension') {
          setSelectedPage(page)
          showEditModal()
        } else {
          editPage({
            name: 'Edit Page',
            params: { pageId: page.id?.substring(1) },
          })
        }
      })

    removeButton &&
      (removeButton.onclick = function () {
        setSelectedPage(page)
        showDeleteModal()
      })
  })
}

const getNode = (diagram: Diagram | null, nodeId: string): any =>
  find(diagram?.nodes, (node) => node.id === nodeId)

const getButtonsConfiguration = (
  nodeId: string | undefined,
  ports: PortModel[] | undefined,
  diagram: Diagram | null,
  initialDiagramData: any,
  removedConnectors: any
) => {
  const buttons: any = []
  map(ports, (port) => {
    if (port.outEdges?.length) {
      map(port.outEdges, (outEdge) => {
        const connectorObject = diagram?.getConnectorObject(outEdge)
        const node = getNode(diagram, connectorObject?.targetID || '')
        const type = node?.data.type
        buttons.push({
          _id: connectorObject?.sourcePortID?.split('-').pop(),
          payloadType: type === 'EXTENSION' ? 'EXTENSION' : 'INTERNAL',
          payload:
            type === 'EXTENSION'
              ? node?.data?.name
              : connectorObject?.targetID?.substring(1),
        })
      })
    }
  })

  return buttons
}

const findRemovedConnector = (removedConnectors: any, portId: any) => {
  let isRemoved = false
  forEach(removedConnectors, (connector) => {
    if (connector === portId) isRemoved = true
  })

  return isRemoved
}

export const exportDiagramConfiguration = (
  diagram: Diagram | null,
  initialDiagramData: any,
  removedConnectors: any
): any => {
  const pages: any = []
  forEach(diagram?.nodes, (node: NodeModel) => {
    const pageType = get(node.data, 'type')
    pages.push({
      _id: get(node.data, '_id'),
      pageType,
      canvasCoordinates: {
        offsetX: node.offsetX,
        offsetY: node.offsetY,
      },
      ...(pageType !== 'EXTENSION' && {
        pageTypeDetails: {
          carouselPage: {
            navigationArray: [
              {
                buttons: getButtonsConfiguration(
                  node?.id,
                  node?.ports,
                  diagram,
                  initialDiagramData,
                  removedConnectors
                ),
              },
            ],
          },
        },
      }),
    })
  })

  return pages
}
