import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckIcon = _resolveComponent("BckIcon")!
  const _component_SeamlessPreview = _resolveComponent("SeamlessPreview")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(
      _ctx.css.pageContainer(_ctx.nodeWidth, _ctx.type === 'extension' ? '80px' : undefined)
    )
  }, [
    _createVNode(_component_BckIcon, {
      "icon-name": "dragHandle",
      "view-box": "0 0 16 10",
      "class-name": _ctx.css.dragHandleIcon
    }, null, 8, ["class-name"]),
    (_ctx.type !== 'extension')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.css.pageTitle)
        }, _toDisplayString(_ctx.name), 3))
      : _createCommentVNode("", true),
    (_ctx.type === 'carousel' || _ctx.type === 'extension')
      ? (_openBlock(), _createBlock(_component_BckIcon, {
          key: 1,
          id: `edit-${_ctx.nodeId}`,
          "icon-name": "builderPageEdit",
          "view-box": "0 0 14 14",
          "class-name": _ctx.css.pageEditIcon
        }, null, 8, ["id", "class-name"]))
      : _createCommentVNode("", true),
    _createVNode(_component_BckIcon, {
      id: `remove-${_ctx.nodeId}`,
      "icon-name": "builderPageRemove",
      "view-box": "0 0 11 13",
      "class-name": _ctx.css.pageRemoveIcon
    }, null, 8, ["id", "class-name"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.css.container)
    }, [
      (_ctx.type === 'carousel')
        ? (_openBlock(), _createBlock(_component_SeamlessPreview, {
            key: 0,
            offers: _ctx.offers,
            height: 264,
            title: _ctx.title,
            project: _ctx.project,
            "has-route": false,
            description: _ctx.description,
            type: "overflow",
            "show-helper-texts": false,
            sizes: {
          fonts: {
            addressBar: '5px',
            title: '7px',
            content: '5px',
            tableItems: '5px',
            navBar: '4px',
          },
          headerIcons: {
            height: '11px',
            width: '11px',
          },
          navBarIcons: {
            height: '9px',
            width: '9px',
          },
          navBarBorderRadius: '0px 0px 13px 13px',
          offersMarginLeft: 14,
          offerCard: {
            fontSize: '5px',
            width: '91px',
            height: '112px',
            buttonHeight: '9px',
          },
        },
            "nav-menu-items": _ctx.nav,
            font: _ctx.project?.branding.googleFontPath,
            "flow-builder": ""
          }, null, 8, ["offers", "title", "project", "description", "nav-menu-items", "font"]))
        : _createCommentVNode("", true),
      (_ctx.type === 'readMore')
        ? (_openBlock(), _createBlock(_component_SeamlessPreview, {
            key: 1,
            height: 264,
            title: _ctx.title,
            "show-terms": false,
            description: _ctx.description,
            project: _ctx.project,
            "has-route": false,
            buttons: _ctx.buttons,
            "read-more-rows": _ctx.readMoreRows,
            type: "readMore",
            "show-helper-texts": false,
            sizes: {
          fonts: {
            addressBar: '5px',
            title: '7px',
            content: '5px',
            tableItems: '5px',
            navBar: '4px',
          },
          headerIcons: {
            height: '11px',
            width: '11px',
          },
          navBarIcons: {
            height: '9px',
            width: '9px',
          },
          navBarBorderRadius: '0px 0px 13px 13px',
          buttonsStyle: {
            fontSize: '5px',
          },
        },
            "nav-menu-items": _ctx.nav,
            font: _ctx.project?.branding.googleFontPath,
            "flow-builder": ""
          }, null, 8, ["title", "description", "project", "buttons", "read-more-rows", "nav-menu-items", "font"]))
        : _createCommentVNode("", true),
      (_ctx.type === 'extension')
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(_ctx.css.extensionName)
          }, _toDisplayString(_ctx.name), 3))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}