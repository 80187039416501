
import { defineComponent, onMounted, reactive } from 'vue'
import { NavigationGuardNext, onBeforeRouteLeave, useRouter } from 'vue-router'
import {
  Diagram,
  ConnectorModel,
  NodeModel,
  ZoomTypes,
  PortModel,
  DiagramConstraints,
  ISelectionChangeEventArgs,
} from '@syncfusion/ej2-diagrams'
import { pull, map, find, remove, forEach, concat, get, set } from 'lodash'
import { useModal } from '@/composables/useModal'
import PagesStructure from '../components/PagesStructure.vue'
import { pagesFlowBuilderStyle as css } from '../styles'
import {
  pageRenderer,
  getNodeWidth,
  onConnectorDrag,
  // onNodeMove,
  getConnectorDefaults,
  appendButtonEvents,
  getNodesConfiguration,
  getDefaultConnectorConfiguration,
  getPortsAndConnectorsConfiguration,
  exportDiagramConfiguration,
  getStructure,
  onConnectorChange,
} from '../helpers'
import ExtensionFormModal from '@/views/productFlowBuilder/components/ExtensionFormModal.vue'
import { Extension } from '@/types/models/productFlowBuilder'
import { transformExtension } from '@/graphqlBackOffice/productBuilder/transformers'

/* eslint-disable @typescript-eslint/no-explicit-any */

interface Canvas {
  diagram: Diagram | null
  diagramStructure: any
  nodes: NodeModel[]
  connectors: ConnectorModel[]
  customConnectors: ConnectorModel[]
  removedConnectors: any[]
  connectedPages: any
  disconnectedPages: any
  selectedPage: any
  isRemovingPage: boolean
}

export default defineComponent({
  components: { ExtensionFormModal, PagesStructure },

  props: {
    diagramData: {
      type: Object,
      default: () => ({}),
    },
    savePages: {
      type: Object,
      default: () => null,
    },
    removePage: {
      type: Object,
      default: () => null,
    },
    removeExtension: {
      type: Object,
      default: () => null,
    },
    isDirty: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Object,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['is-saving', 'on-diagram-change'],

  setup(props, { emit }) {
    const state: Canvas = reactive({
      diagram: null,
      diagramStructure: null,
      nodes: [],
      connectors: [],
      customConnectors: [],
      connectedPages: [],
      disconnectedPages: [],
      removedConnectors: [],
      selectedPage: null,
      isRemovingPage: false,
    })

    const { push } = useRouter()
    const { isModalVisible, closeModal, showModal } = useModal()
    const {
      isModalVisible: isExtensionModalVisible,
      closeModal: closeExtensionModal,
      showModal: showExtensionModal,
    } = useModal()

    const promptWarning = (next: NavigationGuardNext) => {
      if (
        window.confirm(
          'You have unsaved changes which will be lost if you leave the page. Are you sure you want to continue?'
        )
      ) {
        next()
      } else {
        next(false)
      }
    }

    const setSelectedPage = (node: any) => {
      state.selectedPage = node
    }

    const onRemovePage = async () => {
      if (state.diagram) {
        state.isRemovingPage = true
        const deleteMutation =
          state.selectedPage.type === 'extension'
            ? props.removeExtension
            : props.removePage
        deleteMutation
          .mutate({
            id: state.selectedPage._id,
          })
          .then(() => {
            const node = find(
              state.diagram?.nodes,
              (node) => node.id === state.selectedPage.id
            )
            state.diagram?.remove(node)
            state.isRemovingPage = false

            remove(
              state.connectedPages,
              (page: any) => page.id === state.selectedPage.id
            )
            remove(
              state.disconnectedPages,
              (page: any) => page.id === state.selectedPage.id
            )

            closeModal()
          })
      }
    }

    onBeforeRouteLeave(async (to, from, next) => {
      if (!props.isDirty) {
        next()
        return
      }
      promptWarning(next)
    })

    const updateExtension = (extension: Extension) => {
      const nodeId = extension._id
      const found = state.diagram?.nodes?.find((node) => {
        return get(node.data, '_id') === nodeId
      })

      forEach(state.diagram?.nodes, (node) => {
        if (node.data && get(node.data, '_id') === nodeId) {
          set(node.data, 'name', extension.name)
        }
      })

      if (found) {
        const extensionPage = transformExtension(extension)
        const pageTemplate = pageRenderer(
          { ...extensionPage, project: props.project },
          props.diagramData.nav,
          200,
          setSvgLayerZindex
        )

        found.shape = {
          type: 'HTML',
          content: pageTemplate,
        }

        forEach(
          concat(state.disconnectedPages, state.connectedPages),
          (page) => {
            if (page._id === nodeId) {
              page.name = extensionPage.name
            }
          }
        )
      }
    }

    const addExtension = (extension: Extension) => {
      const extensionPage = transformExtension(extension)
      state.disconnectedPages.push({ ...extension, id: extensionPage.id })
      const pageTemplate = pageRenderer(
        { ...extensionPage, project: props.project },
        props.diagramData.nav,
        200,
        setSvgLayerZindex
      )
      const config = getNodesConfiguration(extensionPage, 200, pageTemplate, [])
      if (state.diagram) {
        state.diagram.addNode({
          ...config,
          offsetX: 700,
          offsetY: Math.floor(Math.random() * 700) + 1,
        })

        setSvgLayerZindex(1)
        state.diagram.fitToPage()

        appendButtonEvents(
          [extensionPage],
          showModal,
          showExtensionModal,
          setSelectedPage,
          push
        )
      }
    }

    onMounted(() => {
      const diagram = props.diagramData
      const { connectedPages, disconnectedPages } =
        !!props.diagramData && getStructure(props.diagramData)

      state.connectedPages = connectedPages
      state.disconnectedPages = disconnectedPages

      diagram.pages.map((page: any, index: number) => {
        if (page.type === 'readMore') return
        // Get node width by cards length
        const nodeWidth =
          page.type === 'carousel' ? getNodeWidth(page.offers.length) : 200

        // Render Vue component to html string
        const pageTemplate = pageRenderer(
          { ...page, project: props.project },
          diagram.nav,
          nodeWidth,
          setSvgLayerZindex
        )
        // Get ports configuration
        const ports: PortModel[] = []

        if (page.offers) {
          page.offers.forEach((offer: any, index: number) => {
            const portsAndConnectors = getPortsAndConnectorsConfiguration(
              page.id,
              index,
              nodeWidth,
              offer.buttons || [],
              diagram.pages
            )
            portsAndConnectors.forEach(({ buttonPort, connector }: any) => {
              ports.push(buttonPort)
              if (connector) {
                state.customConnectors.push(connector)
              }
            })
          })
        }

        const config = getNodesConfiguration(
          page,
          nodeWidth,
          pageTemplate,
          ports
        )

        // Initialize nodes
        state.nodes.push(config)
      })

      const defaultConnectorsIds = state.connectors.map(
        (connector) => connector.id
      )

      state.connectors.push(...state.customConnectors)
      // Init Diagram
      state.diagram = new Diagram({
        width: '100%',
        mode: 'Canvas',
        height: `${document.documentElement.clientHeight * 0.78}px`,
        constraints: props.disabled
          ? DiagramConstraints.Zoom
          : DiagramConstraints.Default,
        connectors: state.connectors,
        nodes: state.nodes,
        positionChange: () => {
          !props.isDirty && emit('on-diagram-change', true)
          // return onNodeMove(args, state.diagram as Diagram)
        },
        selectionChange: (selection: ISelectionChangeEventArgs) => {
          state.connectedPages.find((page: any) => {
            page.selected = page.id === selection.newValue[0]?.id
          })
          state.disconnectedPages.find((page: any) => {
            page.selected = page.id === selection.newValue[0]?.id
          })
        },
        connectionChange: (args) => onConnectorChange(args),
        collectionChange: (args) => {
          !props.isDirty && emit('on-diagram-change', true)
          const addingExtension =
            (args.state === 'Changing' || args.state === 'Changed') &&
            args.type === 'Addition' &&
            args.element?.data?.type === 'EXTENSION'
          const removingConnectionOrNode = args.type === 'Removal'

          const draggedSuccessfully =
            !removingConnectionOrNode &&
            !addingExtension &&
            onConnectorDrag(args, state.diagram as Diagram) &&
            args.element.propName === 'connectors'

          if (draggedSuccessfully || removingConnectionOrNode) {
            if (args.state === 'Changed' && args.type === 'Removal') {
              onConnectorRemoval(args.element.targetID)
              const portId = args.element.sourcePortID?.split('-').pop()
              state.removedConnectors.push(portId)
            }
            if (args.state === 'Changed' && args.type === 'Addition') {
              onConnectorAddition(args.element.targetID)
              const portId = args.element.sourcePortID?.split('-').pop()
              const index = state.removedConnectors.indexOf(portId)
              if (index > -1) {
                state.removedConnectors.splice(index, 1)
              }
            }
          }
          if (args.element.propName === 'nodes' && !isModalVisible.value) {
            args.cancel = true
          }
        },
        getConnectorDefaults: (obj: ConnectorModel) =>
          getConnectorDefaults(
            obj as ConnectorModel,
            defaultConnectorsIds as string[]
          ),
        mouseLeave: () => {
          setSvgLayerZindex(1)
        },
      })

      state.diagram.appendTo('#diagram')
      setSvgLayerZindex(1)
      appendButtonEvents(
        props.diagramData.pages,
        showModal,
        showExtensionModal,
        setSelectedPage,
        push
      )
      state.diagram.fitToPage()

      state.diagramStructure = diagram
    })

    const selectPage = (page: any) => {
      state.diagram?.select([state.diagram.getObject(page.id)])
    }

    const onConnectorRemoval = (pageId: string) => {
      let disconnectedPage: any = null
      let disconnectedPageId: any = null
      map(state.connectedPages, (connectedPage, index) => {
        if (connectedPage.id === pageId) {
          disconnectedPage = connectedPage
          disconnectedPageId = index
        }
      })
      if (disconnectedPage?.connections === 1) {
        pull(state.connectedPages, disconnectedPage)
        state.disconnectedPages.push(disconnectedPage)
      } else {
        if (state.connectedPages[disconnectedPageId]?.connections) {
          state.connectedPages[disconnectedPageId].connections -= 1
        }
      }
    }

    const onConnectorAddition = (pageId: string) => {
      let connectedPage: any = null
      map(state.disconnectedPages, (disconnectedPage) => {
        if (disconnectedPage.id === pageId) {
          connectedPage = disconnectedPage
        }
      })

      if (connectedPage) {
        pull(state.disconnectedPages, connectedPage)
        state.connectedPages.push({ ...connectedPage, connections: 1 })
      } else {
        map(state.connectedPages, (connectedPage1) => {
          if (connectedPage1.id === pageId) {
            connectedPage1.connections += 1
          }
        })
      }
    }

    const setSvgLayerZindex = (index: number): void => {
      const diagramSvgLayer = document.getElementById(
        'diagram_diagramLayer_div'
      )
      if (diagramSvgLayer) {
        diagramSvgLayer.style.zIndex = `${index}`
      }
    }
    const diagramZoom = (direction: ZoomTypes) => {
      state.diagram &&
        state.diagram.zoomTo({ type: direction, zoomFactor: 0.1 })
    }

    const saveChanges = async () => {
      const pages = exportDiagramConfiguration(
        state.diagram,
        props.diagramData,
        state.removedConnectors
      )
      emit('is-saving', true)
      await props.savePages.mutate({ pages })
      emit('on-diagram-change', false)
      emit('is-saving', false)
    }

    return {
      state,
      onRemovePage,
      addExtension,
      setSvgLayerZindex,
      diagramZoom,
      isModalVisible,
      isExtensionModalVisible,
      closeExtensionModal,
      closeModal,
      saveChanges,
      selectPage,
      updateExtension,
      css,
    }
  },
})
