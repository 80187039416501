
import { defineComponent, ref, PropType, computed, Ref, watch } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'
import { navigationBarPages as css } from '../styles'
import { getColor } from '@/utils'
import NavigationBarIcons from '@/views/productFlowBuilder/components/NavigationBarIcons.vue'
import BckGridColumn from '@/components/layout/BckGridColumn.vue'
import {
  ApiProductMenu,
  ApiProductPage,
  ITranslationResource,
} from '@/types/graphql/product'
import { forEach, cloneDeep } from 'lodash'
import { cleanTranslations } from '@/graphqlBackOffice/productBuilder/transformers'
import { ProductBuilderService } from '@/graphqlBackOffice/productBuilder'
import { TranslationItem } from '@/types/models'

export default defineComponent({
  components: {
    BckGridColumn,
    draggable: VueDraggableNext,
    NavigationBarIcons,
  },

  props: {
    projectId: {
      type: String,
      required: true,
    },
    menu: {
      type: Array as PropType<ApiProductMenu[]>,
      required: true,
    },
    pages: {
      type: Array as PropType<ApiProductPage[]>,
      required: true,
    },
    availableIcons: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    languages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  emits: ['on-navigation-change'],

  setup(props, { emit }) {
    const saving = ref(false)
    const pageError = ref('')

    const selectedLanguage = ref(props.languages[0])

    const getItemsList = () => {
      const list: string[] = []
      const labels: ITranslationResource[] = []

      if (!props.menu) {
        return {
          list,
          labels,
        }
      }

      props.menu.forEach((element: ApiProductMenu) => {
        const name = props.pages.find(
          (page: ApiProductPage) => page.navigation === element.payload
        )?.name

        if (name) {
          list.push(name)
          labels.push(cloneDeep(element.name))
        }
      })

      return {
        list,
        labels,
      }
    }

    const getItemsListInactive = () =>
      props.pages
        .filter(
          (element: ApiProductPage) =>
            !props.menu
              .map((page: ApiProductMenu) => page.payload)
              .includes(element.navigation)
        )
        .map((page: ApiProductPage) => page.name)

    const itemsListResult = getItemsList()
    const itemsList = ref(itemsListResult.list)
    const labels = ref(itemsListResult.labels)
    const itemsListInactive = ref(getItemsListInactive())

    watch(
      () => props.menu,
      () => {
        const itemsListResult = getItemsList()
        itemsList.value = itemsListResult.list
        labels.value = itemsListResult.labels
        itemsListInactive.value = getItemsListInactive()
      }
    )

    const icons = ref(
      props.menu?.map((element: ApiProductMenu) => element.icon)
    )

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const checkMove = (evt: any) => {
      return (
        evt.relatedContext.element &&
        evt.draggedContext.element != 'Home' &&
        (evt.relatedContext.element != 'Home' ||
          evt.draggedContext.futureIndex != 0) &&
        !(
          itemsListInactive.value?.includes(evt.draggedContext.element) &&
          itemsList.value?.includes(evt.relatedContext.element) &&
          itemsList.value?.length >= 3
        )
      )
    }

    const changeIcon = (icon: string, index: number) => {
      emit('on-navigation-change', true)
      icons.value[index] = icon
    }

    const onChange = () => {
      emit('on-navigation-change', true)
    }

    const changeLabel = (value: string, index: number) => {
      setLabelValue(value, index)

      emit('on-navigation-change', true)
    }

    const changeLanguage = (lang: string) => {
      selectedLanguage.value = lang
    }

    const setLabelValue = (value: string, index: number) => {
      const translation = labels.value[index].translations.find(
        (translation: TranslationItem) =>
          translation.lang === selectedLanguage.value
      )
      if (translation) {
        translation.value = value
      }
    }

    const navMenuItems = computed(() =>
      icons.value.map((iconName: string, index: number) => {
        return {
          name:
            labels.value?.[index].translations.find(
              (x) => x.lang === selectedLanguage.value
            )?.value || '',
          icon: iconName,
        }
      })
    )

    const saveChanges = async () => {
      const savingMenu: Ref<ApiProductMenu[]> = ref([])

      forEach(props.menu, (element: ApiProductMenu, index: number) => {
        const translations = cleanTranslations(labels.value[index])
        savingMenu.value.push({
          icon: icons.value[index],
          iconActive: icons.value[index] + '._negative',
          name: {
            translations,
          },
          payload:
            props.pages.find(
              (element) => element.name === itemsList.value[index]
            )?.navigation ?? '',
        })
      })
      try {
        saving.value = true
        await ProductBuilderService.saveProduct(
          props.projectId,
          savingMenu.value
        )
        emit('on-navigation-change', false)
      } catch (error) {
        pageError.value =
          error?.response?.data?.error ||
          'Something went wrong on project saving, please try again later.'
      } finally {
        saving.value = false
      }
    }

    return {
      icons,
      navMenuItems,
      onChange,
      enabled: true,
      itemsList,
      labels,
      itemsListInactive,
      saveChanges,
      checkMove,
      changeIcon,
      pageError: computed(() => pageError.value),
      saving,
      getColor,
      css,
      selectedLanguage,
      changeLabel,
      changeLanguage,
    }
  },
})
