
import { computed, defineComponent, ref, Ref, watch } from 'vue'
import LanguageTabs from '@/components/common/LanguageTabs.vue'
import SeamlessPreview from '@/components/common/SeamlessPreview.vue'
import ProjectsService from '@/graphqlBackOffice/projects/service'
import { pageContentLoading } from '@/components/common/styles'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import { ProductTypeItem } from '@/types/models'
import { filter, forEach, isEmpty, size, values as arrayValues } from 'lodash'
import { createEditPageStyle } from '@/views/productFlowBuilder/styles'
import { formError } from '@/components/ui/styles'
import PageTypeCard from '@/components/common/PageTypeCard.vue'
import { useTranslatableFields } from '@/composables/useTranslatableFields'
import { ProductBuilderService } from '@/graphqlBackOffice/productBuilder'
import { useRouter } from 'vue-router'
import ProductCheckModal from '@/components/common/ProductCheckModal.vue'
import CardsSelection from '@/components/common/CardsSelection.vue'
import { CarouselPageCard } from '@/types/components/UiTypes'
import { scrollTo } from '@/utils/animations'
import { isAuthorized } from '@/services/auth/authService'
import { Permissions } from '@/services/auth/permissions'

export default defineComponent({
  components: {
    CardsSelection,
    PageTypeCard,
    LanguageTabs,
    SeamlessPreview,
    ProductCheckModal,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    pageId: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const { loading: loadingProject, project } = ProjectsService.getProjectById(
      props.id
    )
    const translatableFields = ['title', 'description']
    const requiredTranslatableFields = ['title']
    const languages: Ref<string[] | undefined> = ref([])
    const selectedLanguage = ref('en')
    const { push } = useRouter()
    const pageError = ref('')
    const saving = ref(false)
    const productId = ref('')
    const cardsSelectionRef = ref()
    const cardsError = ref('')

    const {
      copyTranslationFromSavedEntity,
      getTranslationsForFields,
      isAllFormTranslated,
      translatedFields,
      updateTranslation,
    } = useTranslatableFields()

    const pageTypes: Ref<ProductTypeItem[]> = ref([
      {
        name: 'Offer / Card',
        iconName: 'offerCardIcon',
        disabled: false,
        selected: true,
      },
      // {
      //   name: 'ContactForm',
      //   iconName: 'contactFormIcon',
      //   disabled: true,
      //   selected: false,
      // },
      // { name: 'Map', iconName: 'mapIcon', disabled: true, selected: false },
      // {
      //   name: 'Colapsible',
      //   iconName: 'colapsibleIcon',
      //   disabled: true,
      //   selected: false,
      // },
      // {
      //   name: 'Custom',
      //   iconName: 'customIcon',
      //   disabled: true,
      //   selected: false,
      // },
    ])

    const selectPageType = (name: string) => {
      const pageItem = pageTypes.value.find((item) => item.name === name)
      if (pageItem && !pageItem.disabled) {
        pageItem.selected = true
        forEach(pageTypes.value, (item) => {
          if (item.name !== pageItem.name) {
            item.selected = false
          }
        })
      }
    }

    const { values, handleSubmit, meta, setValues } = useForm({
      validationSchema: yup.object().shape({
        name: yup.string().required('This field is required.'),
        title: yup.string().required('This field is required.'),
      }),
    })

    const changeLanguage = (lang: string) => {
      pageError.value = ''
      selectedLanguage.value = lang
      setValues({
        ...values,
        ...translatedFields(translatableFields, [], lang),
      })
    }
    const selectedCards = computed(() => {
      return filter(
        cardsSelectionRef.value?.selectedCards,
        (card) => !isEmpty(card)
      )
    })
    const previewCards = computed(() => {
      return cardsSelectionRef.value?.previewCards
    })

    const existingCards: Ref<CarouselPageCard[]> = ref([])
    const { loading: loadingPage, page } = props.pageId
      ? ProductBuilderService.getPage(props.pageId)
      : { loading: ref(false), page: ref(null) }

    const setInitialFormValues = () => {
      copyTranslationFromSavedEntity(page.value, translatableFields)
      if (page.value) {
        setValues({
          ...page.value,
          ...translatedFields(translatableFields, [], selectedLanguage.value),
        })
      }

      existingCards.value = page.value?.cards || []
    }

    const loading = computed(() => loadingPage.value || loadingProject.value)

    watch(
      () => loading.value,
      (loading) => {
        if (!loading) {
          languages.value = project.value?.languages
          selectedLanguage.value = languages.value?.[0] || 'en'

          setInitialFormValues()
        }
      }
    )

    const savePage = handleSubmit(async () => {
      if (
        size(arrayValues(languages.value)) > 1 &&
        !isAllFormTranslated(requiredTranslatableFields, languages.value || [])
      ) {
        scrollTo('.language-tabs')
        pageError.value =
          'Some required fields are empty. Please check all language tabs.'
        return
      }
      if (isEmpty(selectedCards.value)) {
        scrollTo('.cards')
        cardsError.value = 'Please select at least one card for the funnel.'
        return
      }

      try {
        saving.value = true
        productId.value
        await ProductBuilderService.savePage(
          {
            ...values,
            ...getTranslationsForFields(translatableFields),
            cards: selectedCards.value,
          },
          'CarouselPage',
          productId.value,
          props.id
        )
        push({ name: 'Flow Builder' })
      } catch (error) {
        pageError.value =
          error?.response?.data?.error ||
          'Something went wrong, please try again later.'
      } finally {
        saving.value = false
      }
    })

    const canEdit = isAuthorized(Permissions.EDIT_FIELDS_ON_PAGE, props.id)

    return {
      changeLanguage,
      cardsError,
      cardsSelectionRef,
      createEditPageStyle,
      css: { pageContentLoading, formError },
      existingCards,
      isEmpty,
      languages,
      loading,
      loadingPage,
      meta,
      pageError,
      pageTypes,
      previewCards,
      productId,
      push,
      savePage,
      saving,
      selectedLanguage,
      updateTranslation,
      selectPageType,
      values,
      canEdit,
      project,
    }
  },
})
