import { style } from 'typestyle'
import { getColor } from '@/utils'

const icons = style({
  width: '100%',
  minHeight: '100px',
  padding: '0 0 12px 0',
})

const iconSelect = style({
  height: '39px',
  width: '71px',
  backgroundColor: getColor('primary-blue'),
  borderRadius: '5px',
})

const groupIcons = style({
  minHeight: '35px',
  width: '288px',
  backgroundColor: getColor('white'),
  display: 'inline-flex',
  borderRadius: '5px',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
  border: '5px',
  textAlign: 'center',
  position: 'absolute',
  zIndex: 2000,
  flexWrap: 'wrap',
})

const arrowSelected = style({
  transform: 'rotate(180deg) translate(17px, 24px)',
})

const groupItem = style({
  textAlign: 'center',
  $nest: {
    '&:hover': {
      background: getColor('primary-navy-40'),
    },
  },
})

const itemName = style({
  color: getColor('primary-blue'),
  fontWeight: 'bold',
  fontSize: '12px',
})

const label = style({
  paddingRight: '36px',
})

const languageTabs = style({
  marginBottom: '18px !important',
})

export default {
  icons,
  iconSelect,
  groupIcons,
  groupItem,
  arrowSelected,
  itemName,
  label,
  languageTabs,
}
