import { createApp, h } from 'vue'
import PageSeamlessPreviewTemplate from '../components/PageSeamlessPreviewTemplate.vue'
import { OfferListItem, ReadMoreRow } from '@/types/models'

export interface DiagramPage {
  _id?: string // the original entity id
  id: string // the original entity id preceded by "a"
  project: any
  name: string
  title: string
  description: string
  type: string
  offers?: OfferListItem[]
  readMoreRows?: ReadMoreRow[]
  buttons?: []
  offsetX: number
  offsetY: number
  navigation: string
}

export default (
  page: DiagramPage,
  nav: any,
  nodeWidth: number,
  onHover: any
): string => {
  const tempApp = createApp({
    render() {
      return h(PageSeamlessPreviewTemplate, {
        project: page.project,
        nodeId: page.id,
        name: page.name,
        type: page.type,
        offers: page.offers,
        readMoreRows: page.readMoreRows,
        buttons: page.buttons,
        nodeWidth,
        onHover,
        title: page.title || '',
        description: page.description || '',
        nav,
      })
    },
  })

  const el = document.createElement('div')
  const mountedApp = tempApp.mount(el)

  return mountedApp.$el.outerHTML
}
