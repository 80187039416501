import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckIcon = _resolveComponent("BckIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.pageTypeCardStyle.card(_ctx.selected, _ctx.disabled)),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('selected', _ctx.name)))
  }, [
    (_ctx.iconName)
      ? (_openBlock(), _createBlock(_component_BckIcon, {
          key: 0,
          "icon-name": _ctx.iconName,
          width: "55px",
          height: "55px",
          fill: _ctx.selected ? _ctx.getColor('white') : _ctx.getColor(),
          viewBox: "2 2 55 55",
          class: "align",
          opacity: _ctx.selected ? '1' : '0.1'
        }, null, 8, ["icon-name", "fill", "opacity"]))
      : _createCommentVNode("", true),
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.pageTypeCardStyle.contentText)
        }, _toDisplayString(_ctx.text), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.pageTypeCardStyle.name(_ctx.selected))
    }, _toDisplayString(_ctx.name), 3)
  ], 2))
}