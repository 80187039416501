
import {
  defineComponent,
  computed,
  ref,
  watchEffect,
  Ref,
  reactive,
  onMounted,
  watch,
} from 'vue'
import { useRouter, useRoute, LocationQuery } from 'vue-router'
import { pageContentLoading } from '@/components/common/styles'
import { useTabs } from '@/composables/useTabs'
import PagesFlowBuilder from './PagesFlowBuilder.vue'
import NavigationBar from './NavigationBar.vue'
import { isSuperAdmin } from '@/graphqlBackOffice/auth/session'
import ProjectsService from '@/graphqlBackOffice/projects/service'
import { ProductBuilderService } from '@/graphqlBackOffice/productBuilder'
import SeamlessPreview from '@/components/common/SeamlessPreview.vue'
import ProductCheckModal from '@/components/common/ProductCheckModal.vue'
import { formError } from '@/components/ui/styles'
import { ApiProductMenu } from '@/types/graphql/product'
import ExtensionFormModal from '@/views/productFlowBuilder/components/ExtensionFormModal.vue'
import { useModal } from '@/composables/useModal'
import { Extension } from '@/types/models/productFlowBuilder'
import { isAuthorized } from '@/services/auth/authService'
import { Permissions } from '@/services/auth/permissions'

/* eslint-disable @typescript-eslint/no-explicit-any */

export default defineComponent({
  components: {
    ExtensionFormModal,
    NavigationBar,
    PagesFlowBuilder,
    SeamlessPreview,
    ProductCheckModal,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { push } = useRouter()
    const { saveProductChanges, removePage, removeExtension } =
      ProductBuilderService.useMutations()
    const tabsData = ref([
      { key: 'pages', name: 'Pages' },
      { key: 'navigation', name: 'Navigation' },
    ])
    const flowBuilder = ref()

    const onExtensionAdded = (extension: Extension) =>
      flowBuilder.value?.addExtension(extension)

    const availableIcons = ref([
      'homeNav',
      'box',
      'bell',
      'gift',
      'grid',
      'layers',
      'message-circle',
      'phoneNav',
      'pie-chart',
      'send',
      'share',
      'shopping-cart',
      'userNav',
      'info',
      'cloud',
      'etopup._nav_bar._icon2',
      'beeline_ge._product._icon1',
      'map-pin',
      'contact-us',
    ])
    const nav = ref()
    const languages: Ref<string[] | undefined> = ref([])
    const productId = ref('')
    const state = reactive({
      pageError: computed(() => {
        if (selectedTab.value === 'navigation') {
          return nav?.value?.pageError
        }

        return ''
      }),

      diagram: ref(),
      isSavingDiagram: false,
      isDiagramDirty: false,
      diagramLoading: reactive({}),

      carouselPages: ref(),
      loadingCarouselPages: ref(),
      menu: ref(),
      loadingMenu: ref(),
      isNavigationDirty: false,

      activePages: ref(),

      loadingLearnPages: ref(),
      isEditorDirty: false,
    })

    const route = useRoute()
    const { currentRoute } = useRouter()

    let refetchPagesQuery: any = undefined
    let refetchProduct: any = undefined
    let refetchCarouselPages: any = undefined

    const {
      isModalVisible: isExtensionModalVisible,
      showModal: showExtensionModal,
      closeModal: closeExtensionModal,
    } = useModal()

    const { loading: loadingProject, project } = ProjectsService.getProjectById(
      props.id,
      'cache-first'
    )
    const changeTab = (
      tabKey: string,
      currentQuery?: LocationQuery | undefined
    ) => {
      if (state.isDiagramDirty && selectedTab.value === 'pages') {
        promptWarning(tabKey, currentQuery)
      } else if (
        state.isNavigationDirty &&
        selectedTab.value === 'navigation'
      ) {
        promptWarning(tabKey, currentQuery)
      } else {
        changeSelectedTab(tabKey, currentQuery)
      }
    }

    const fetchData = () => {
      if (selectedTab.value === 'pages') {
        if (!refetchPagesQuery) {
          const { loading, refetch, result } =
            ProductBuilderService.getProductPagesAndExtensions(props.id)
          state.diagramLoading = loading
          state.diagram = result || {}
          refetchPagesQuery = refetch
        } else {
          refetchPagesQuery()
        }
      }
      if (selectedTab.value === 'navigation') {
        if (!refetchCarouselPages) {
          const {
            pages,
            loading: loadingCarouselPages,
            refetch,
          } = ProductBuilderService.getProductPagesAndExtensions(props.id)
          state.carouselPages = pages
          state.loadingCarouselPages = loadingCarouselPages
          refetchCarouselPages = refetch
        } else {
          refetchCarouselPages()
        }
        if (!refetchProduct) {
          const {
            loading: loadingMenu,
            refetch,
            menu,
          } = ProductBuilderService.getProductMenu(props.id)
          state.menu = menu
          state.activePages = computed(() =>
            menu?.value?.map((element: ApiProductMenu) => {
              return {
                id: element.payload,
                icon: element.icon,
                name: element.pageName || '',
              }
            })
          )
          state.loadingMenu = loadingMenu
          refetchProduct = refetch
        } else {
          refetchProduct()
        }
      }
    }

    const tabInitialvalue = route.query.selectedTab
      ? (route.query.selectedTab as string)
      : 'pages'

    const { changeSelectedTab, selectedTab } = useTabs(tabInitialvalue, true)

    watch(currentRoute, fetchData)

    const promptWarning = (
      tabKey: string,
      currentQuery?: LocationQuery | undefined
    ) => {
      if (
        window.confirm(
          'You have unsaved changes which will be lost if you leave the page. Are you sure you want to continue?'
        )
      ) {
        state.isDiagramDirty = false
        state.isNavigationDirty = false
        state.isEditorDirty = false
        changeSelectedTab(tabKey, currentQuery)
      }
    }

    onMounted(() => {
      fetchData()
    })

    watchEffect(() => {
      if (!loadingProject.value) {
        languages.value = project.value?.languages
        productId.value = project.value?.productId._id || ''
      }
    })

    const isSaving = (loading: boolean) => {
      state.isSavingDiagram = loading
    }

    const onDiagramChange = (isDirty: boolean) => {
      state.isDiagramDirty = isDirty
    }

    const onNavigationChange = (isDirty: boolean) => {
      state.isNavigationDirty = isDirty
    }

    const onEditorChange = (isDirty: boolean) => {
      state.isEditorDirty = isDirty
    }

    const canAddPage = isAuthorized(Permissions.ADD_NEW_PAGE, props.id)

    const canEditPage = isAuthorized(Permissions.ADD_NEW_PAGE, props.id)

    const canEditCanvas = isAuthorized(
      Permissions.MAKE_CHANGES_ON_CANVAS,
      props.id
    )

    const canEditNavigation = isAuthorized(
      Permissions.EDIT_NAVIGATION,
      props.id
    )

    return {
      isSuperAdmin,
      isSaving,
      onDiagramChange,
      onNavigationChange,
      onEditorChange,
      onExtensionAdded,
      removePage,
      removeExtension,
      languages,
      nav,
      push,
      changeTab,
      saveProductChanges,
      selectedTab,
      tabsData,
      state,
      saving: computed(
        () =>
          state.isSavingDiagram ||
          nav?.value?.saving
      ),
      availableIcons,
      fetchData,
      flowBuilder,
      loading: computed(
        () =>
          loadingProject.value ||
          state.loadingLearnPages ||
          state.loadingCarouselPages ||
          state.loadingMenu
      ),
      project,
      css: { pageContentLoading, formError },
      productId,
      showExtensionModal,
      isExtensionModalVisible,
      closeExtensionModal,
      canAddPage,
      canEditPage,
      canEditCanvas,
      canEditNavigation,
    }
  },
})
