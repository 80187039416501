
import { defineComponent, PropType, ref, watch } from 'vue'
import { getColor } from '@/utils'
import { navigationBarIcons as css } from '../styles'
import LanguageTabs from '@/components/common/LanguageTabs.vue'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import { ITranslationResource } from '@/types/graphql/product'
import { TranslationItem } from '@/types/models'

export default defineComponent({
  components: {
    LanguageTabs,
  },

  props: {
    title: {
      type: String,
      default: 'Page',
    },
    icons: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    availableIcons: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    languages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    selectedLanguage: {
      type: String,
      required: true,
    },
    labels: {
      type: Array as PropType<ITranslationResource[]>,
      default: () => [],
    },
  },

  emits: ['change-icon', 'change-language', 'change-label'],

  setup(props, { emit }) {
    const iconName = ref(props.icons)
    const displayIcons = ref([false, false, false])

    const handleSelect = (index: number) => {
      if (props.disabled) return
      displayIcons.value = displayIcons.value.map((item, it) =>
        it == index ? !item : false
      )
    }

    const select = (icon: string, index: number) => {
      iconName.value[index] = icon
      emit('change-icon', icon, index)
    }

    const changeLabel = (value: string, index: number) => {
      emit('change-label', value, index)
    }

    const changeLanguage = (lang: string) => {
      emit('change-language', lang)
    }

    const getValuesForSelectedLanguage = () => {
      return {
        label: props.labels.map(
          (label: ITranslationResource) =>
            label.translations.find(
              (translationItem: TranslationItem) =>
                translationItem.lang === props.selectedLanguage
            )?.value || ''
        ),
      }
    }

    const { setValues } = useForm({
      validationSchema: yup.object().shape({
        label: yup.array(yup.string().required('Required')),
      }),
      initialValues: getValuesForSelectedLanguage(),
    })

    setValues(getValuesForSelectedLanguage())

    watch(
      () => props.selectedLanguage,
      () => {
        setValues(getValuesForSelectedLanguage())
      }
    )

    return {
      handleSelect,
      select,
      getColor,
      iconName,
      displayIcons,
      changeLanguage,
      changeLabel,
      css,
    }
  },
})
